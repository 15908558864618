// config.js

const bizIcon = 'https://mariofoudationrepair.com/wp-content/uploads/2024/12/cropped-LOGO-Mario-Foudation-Repair-140x140.png';
const myBizIcon = 'https://mybizsolutions.us/static/media/icon-app-logo.83ff8bf39a11df9fb7ac.jpg';
const imageOne = "https://mariofoudationrepair.com/wp-content/uploads/2024/12/MFR.png";
const imageTwo = "https://mariofoudationrepair.com/wp-content/uploads/2024/12/341538225_794714234971959_1513317553407457555_n.jpg";
const imageThree = "https://mariofoudationrepair.com/wp-content/uploads/2024/12/441509621_953061460162984_6140713078130538832_n.jpg";
const imageFour = "https://mariofoudationrepair.com/wp-content/uploads/2024/12/131243004_416939229719073_8790278060507469149_n.jpg";
const imageFive = "https://mariofoudationrepair.com/wp-content/uploads/2024/12/MFR-1.png";

const redirectLink = "https://mariofoudationrepair.com/";

export const siteConfig = {
  title: "Mario Foundation Repair",
  metaDescription: "Mario Foundation Repair",
  theme: {
    primaryColor: "red-600",
    secondaryColor: "gray-900",
    accentColor: "text-red-600"
  },
  logo: {
    src: bizIcon,
    alt: "Mario Foundation Repair Icon",
  },
  navigation: [
    { name: "Services", sectionId: "services" },
    { name: "About", sectionId: "about" },
    { name: "Location", sectionId: "location" },
  ],
  businessDetails: {
    name: "Mario Foundation Repair",
    headline: "Your Trusted Foundation Repair Experts in Dallas & Hunt County",
    address: "Dallas, Texas",
    description: `
        Your home deserves a solid foundation! With over 15 years of experience, Mario Foundation Repair is your trusted partner for foundation repair services in Dallas and Hunt County. Our dedicated team is committed to providing long-lasting, high-quality solutions to protect the stability of your home. Whether you're dealing with cracks, settling, or uneven floors, we deliver precise, reliable repairs that ensure your property remains safe and secure. Trust us to safeguard your home with expertise and professionalism.
    `,
  },
  images: {
    business: {
      src: imageOne,
      alt: "Your Trusted Foundation Repair Experts in Dallas & Hunt County",
      width: 2432,
      height: 1442
    }
  },
  features: [
    {
        description: 
          "15+ Years of Expertise: With over a decade and a half of industry experience, we provide reliable and professional foundation repair services.",
    },
    {
        description: 
          "Quality Materials and Practices: Our team uses the best materials and proven techniques to ensure durable, long-lasting results for every project.",
    },
    {
        description: 
          "Free Estimates and Expert Guidance: Contact us today for a free estimate and personalized solutions to protect the stability and value of your home or business.",
    },
  ],
  services: {
    sectionTitle: "Our Foundation Repair Services",
    description: 
      "Mario Foundation Repair provides expert foundation repair services tailored to meet the unique needs of homeowners and businesses in Dallas, TX, and Hunt County. With over 15 years of experience, we deliver reliable, high-quality solutions that ensure the stability and longevity of your property.",
    callouts: [
      {
        name: 'Foundation Repair',
        description: 
          "Effective and long-lasting solutions to restore the integrity of your foundation and prevent long-term structural issues. We ensure your home or business stays safe and secure.",
        imageSrc: imageTwo,
        imageAlt: 'Expert team performing foundation repair on a residential property.',
      },
      {
        name: 'Pier Beam Repair',
        description: 
          "Reinforcement and repair of support beams to enhance the stability of your property. Our team ensures your pier beam structure is strong and reliable.",
        imageSrc: imageThree,
        imageAlt: 'Technicians repairing pier beams for a commercial structure.',
      },
      {
        name: 'Subfloor Maintenance and Repair',
        description: 
          "Prevent structural damage caused by moisture or wear with our subfloor maintenance and repair services. We address issues promptly to preserve the health of your property.",
        imageSrc: imageFour,
        imageAlt: 'Team conducting subfloor maintenance for a residential building.',
      },
    ],
    otherServices: [
      "Plumbing Tunnel",
    ]
  },
  about: {
    sectionTitle: "About Us",
    description:   
      "Mario Foundation Repair has been the trusted choice for foundation repair in Dallas, TX, and Hunt County for over 15 years. Our experienced team is committed to delivering long-lasting, high-quality solutions that protect the stability of your home or business. We take pride in using durable materials and advanced techniques to ensure every project is completed to the highest standards. With a focus on personalized service, we provide free, no-obligation estimates and work closely with you to design tailored solutions. Trust Mario Foundation Repair for professionalism, reliability, and results that exceed your expectations.",
    image: {
      src: imageFive,
      alt: "Mario Foundation Repair team delivering expert foundation repair services in Dallas, TX.",
      width: 1200,
      height: 800,
    },
  },
  location: {
    sectionTitle: "Our Location",
    destinationAddress: "Dallas, Texas, USA",
    googleMapsEmbedUrl: 
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.022297472338!2d-96.80161168479636!3d32.77666417975724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864e9932d8c6c4af%3A0xabcdef123456789!2sDallas%2C%20TX%2C%20USA!5e0!3m2!1sen!2sus!4v1710407450828!5m2!1sen!2sus",
    subCategories: [
      { 
        name: 'Apple Maps', 
        url: 'https://maps.apple.com/?address=Dallas,%20TX,%20USA',
      },
      { 
        name: 'Bing Maps', 
        url: 'https://www.bing.com/maps?q=Dallas,+TX,+USA&FORM=HDRSC6',
      },
      { 
        name: 'Google Earth', 
        url: 'https://earth.google.com/web/search/Dallas,+TX,+USA/@32.776664,-96.796988,15a,814.57589625d,35y,0h,0t,0r',
      },
      { 
        name: 'Google Maps', 
        url: 'https://www.google.com/maps/place/Dallas,+TX,+USA/@32.776664,-96.796988,15z',
      },
    ],
  },
  contact: {
    sectionTitle: "Get in Touch",
    description: 
      "Protect the stability and safety of your home with Mario Foundation Repair. Whether you need expert advice, a detailed assessment, or a free estimate, our experienced team is here to help. Contact us today to ensure your foundation is secure and your investment is protected with high-quality, long-lasting solutions.",
    socialLinks: {
      facebook: redirectLink,     
      instagram: redirectLink,     
      twitter: redirectLink,     
      linkedin: redirectLink,     
      youtube: redirectLink,     
    },
    contactNumbers: [
      "Mobile: (469) 338-6808",
    ],
    logo: bizIcon,
    emailRecipient: "info@mariofoudationrepair"
  },
  footer: {
    bizSolutionsLogo: myBizIcon
  }
};


